exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-category-featured-tsx": () => import("./../../../src/pages/category/featured.tsx" /* webpackChunkName: "component---src-pages-category-featured-tsx" */),
  "component---src-pages-category-financial-planning-tsx": () => import("./../../../src/pages/category/financial-planning.tsx" /* webpackChunkName: "component---src-pages-category-financial-planning-tsx" */),
  "component---src-pages-category-investment-management-tsx": () => import("./../../../src/pages/category/investment-management.tsx" /* webpackChunkName: "component---src-pages-category-investment-management-tsx" */),
  "component---src-pages-category-retirement-planning-tsx": () => import("./../../../src/pages/category/retirement-planning.tsx" /* webpackChunkName: "component---src-pages-category-retirement-planning-tsx" */),
  "component---src-pages-category-tax-strategies-tsx": () => import("./../../../src/pages/category/tax-strategies.tsx" /* webpackChunkName: "component---src-pages-category-tax-strategies-tsx" */),
  "component---src-pages-disclosures-tsx": () => import("./../../../src/pages/disclosures.tsx" /* webpackChunkName: "component---src-pages-disclosures-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-retirement-calculator-tsx": () => import("./../../../src/pages/retirement-calculator.tsx" /* webpackChunkName: "component---src-pages-retirement-calculator-tsx" */),
  "component---src-templates-blog-article-template-tsx": () => import("./../../../src/templates/BlogArticleTemplate.tsx" /* webpackChunkName: "component---src-templates-blog-article-template-tsx" */),
  "component---src-templates-tag-page-template-tsx": () => import("./../../../src/templates/TagPageTemplate.tsx" /* webpackChunkName: "component---src-templates-tag-page-template-tsx" */)
}

